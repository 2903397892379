const apiURL = 'https://mana-zone.online/api/';

export async function getAlphaClashCardList() {
	const reqURL = apiURL + 'index.php?AlphaClashCardList';
	return fetch(reqURL, {
		method: 'GET',
	}).then((response) => {
		if(!response.ok){
			throw new Error('Get card list Failed');
		} else {
			return response.json();
		};
	}).catch((error) => {
		console.error('Error: ', error);
	});
};


export async function logWebAnalytics() {
    try {
		const reqURL = apiURL + 'myWebAnalytics.php';
        await fetch(reqURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                url: window.location.href, // Full URL of the current page
            }),
        });
    } catch (error) {
        console.error('Error logging analytics:', error);
    };
};